import '../styles/App.css';

const About = () => {

    return(
        <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
            <h2 >Egg Nepal Pvt. Ltd.</h2>
            <div style={{ background: 'none', textAlign: 'justify', opacity: '75%', fontSize: '16px', }} >
                <div>We are a company based on kathmandu that supply eggs to stores.</div>
                <div>We deliver fresh eggs from our own farm and other farms, directly to your store.</div>
                <div>We deliver eggs in boxes and one box contains seven crates of eggs.</div>
            </div>

            <div style={{ fontSize: '14px', opacity: '75%', marginTop: '20px' }}>
                <div>Phone No: 9861229280</div>
                <div>Email: <div id='email_link' style={{ display: 'inline', color: 'blue' }} onClick={() => window.open('https://mail.google.com/mail/u/0/?to=contacteggnepal@gmail.com&fs=1&tf=cm', '_blank').focus()} >contacteggnepal@gmail.com</div></div>
            </div>

            <div style={{marginTop: '20px'}}>
                <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d1397.4998971430066!2d85.35206169389642!3d27.73605613320062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d27.7361218!2d85.3532165!5e0!3m2!1sen!2snp!4v1687703788874!5m2!1sen!2snp"
                    style={{border: 0, height: 300, width:'100%' }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" />
            </div>
        </div>
    )
}

export default About;