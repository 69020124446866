import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { handleMessage } from '../store/loginSlice';
import { get, post } from './Navigation';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { style } from './ViewOrders';

export const UserDiv = () => {

    const ud = useSelector((state) => state.login.user);

    const dispatch = useDispatch();

    // for modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [p1, setP1 ] = useState('');
    const [p2, setP2 ] = useState('');
    const [p3, setP3 ] = useState('');
  
    const changePassword = () => {
      if(p1 && p2 && p3){
        if (p2 !== p3){
          dispatch(handleMessage('Confirm Passwords do not Match!!'));
        } else {
          post('change-password', {current: p1, new: p2});
          handleClose();
        }
      } else {
        dispatch(handleMessage('Please Enter all Fields!!'));
      }
    };
  
    // for password
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const [showPassword1, setShowPassword1] = useState(false)
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event) => {
      event.preventDefault();
    };
  
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event) => {
      event.preventDefault();
    };

  return(
    <Box
        id='box'
        sx={{
          position: 'relative',
          marginTop: '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '10px',
          borderRadius: '7px',
          width: '49%',
          fontSize: '14px'
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{width: '50%', textAlign: 'right', paddingRight: '5px'}} >Username:</div>
          <div style={{width: '50%', fontWeight: 'bold' }} >{ud.username}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{width: '50%', textAlign: 'right', paddingRight: '5px'}} >Full Name:</div>
          <div style={{width: '50%', fontWeight: 'bold' }} >{ud.full_name}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{width: '50%', textAlign: 'right', paddingRight: '5px'}} >Address:</div>
          <div style={{width: '50%', fontWeight: 'bold'}} >{ud.store_address}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
            <Button variant="contained" onClick={handleOpen}>Change Password</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Change Password
                </Typography>
                <FormControl
                  sx={{ m: 1, width: '96%' }}
                  variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">Current Password</InputLabel>
                      <Input
                        placeholder='********'
                        id="standard-adornment-password"
                        onChange={e => setP1(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        startAdornment={
                          <InputAdornment position="start">
                            <LockPersonIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                </FormControl>

                <FormControl
                  sx={{ m: 1, width: '96%' }}
                  variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                      <Input
                        placeholder='********'
                        id="standard-adornment-password"
                        onChange={e => setP2(e.target.value)}
                        type={showPassword1 ? 'text' : 'password'}
                        startAdornment={
                          <InputAdornment position="start">
                            <LockPersonIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword1}
                            >
                              {!showPassword1 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                </FormControl>

                <FormControl
                  sx={{ m: 1, width: '96%' }}
                  variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">Confim New Password</InputLabel>
                      <Input
                        placeholder='********'
                        id="standard-adornment-password"
                        onChange={e => setP3(e.target.value)}
                        type={showPassword2 ? 'text' : 'password'}
                        startAdornment={
                          <InputAdornment position="start">
                            <LockPersonIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                            >
                              {!showPassword2 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                </FormControl>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={changePassword}>Change</Button>
                </div>
              </Box>
            </Modal>
        </div>
    </Box>
  );
}

const EditOrder = ({record, getMyOrders}) => {

  const dispatch = useDispatch();

  const [size, setSize] = useState(record.size);
  const [quantity, setQuantity] = useState(record.quantity);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const editOrder = () => {
    if(record.size === size && record.quantity === quantity){
      dispatch(handleMessage("no changes found to make an edit!"))
    } else {
      post('edit-order', {
        id: record.id,
        quantity: quantity,
        size: size
      }).then(getMyOrders).then(handleClose1);
    }
  }

  return(
    <div style={{ display: 'inline' }}>
      <Button variant="contained" style={{ marginRight: '5px'}} onClick={handleOpen1} >Edit</Button>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Order
                </Typography>
                <FormControl style={{ marginTop: '10px' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Size</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={size}
                    onChange={e => setSize(e.target.value)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="large" control={<Radio />} label="Large" />
                    <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                    <FormControlLabel value="small" control={<Radio />} label="Small" />
                  </RadioGroup>
                </FormControl>

                <TextField
                  id="standard-number"
                  label="Number"
                  type="number"
                  value={quantity}
                  onChange={e => setQuantity(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                />
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose1}>Close</Button>
                <Button variant="contained" onClick={editOrder}>Save</Button>
                </div>
              </Box>
      </Modal>
    </div>
  )
}

const CancelOrder = ({record, getMyOrders}) => {

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const cancelOrder = () => {
    post('cancel-order', {id: record.id})
    .then(getMyOrders)
    .then(handleClose2);
  }

  return(
    <div style={{ display: 'inline' }}>
      <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }} onClick={handleOpen2}>Cancel</Button>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Order Cancellation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to cancel the order?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose2}>Close</Button>
          <Button variant="contained" onClick={cancelOrder}>Cancel Order</Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

const UserProfile = () => {

  const [orders, setOrders] = useState([]);

  const getMyOrders = () => {
    get('my-orders').then(data => {
      setOrders(data);
    });
  };

  useEffect(getMyOrders, []);

  return (
    <div className="orders">
      <UserDiv />

      <h4 id="oh" style={{ textAlign: 'center'}}>My Orders</h4>
      <div id="i-orders">
        {orders.map(order => (
          <div className="order" key={order.id}>
            <h3 id="name">{order.full_name}</h3>
            <p id="address">{order.store_address}</p>
            <h4>{order.quantity} Boxes, {order.size}</h4>
            <div id='btnn'>
              <EditOrder record={order} getMyOrders={getMyOrders} />
              <CancelOrder record={order} getMyOrders={getMyOrders} />  
            </div>
          </div> 
        ))}
      </div>
    </div>
  );
};

export default UserProfile;