import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

const Loading = () => {

    const open = true;
    const data = useSelector((state) => state.login.data);
    
    if(data){
        return <></>  
    } else {
        return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: 9999 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>)
    }
}

export default Loading;