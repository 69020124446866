import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { handleMessage } from '../store/loginSlice';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { post } from './Navigation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '10px 10px 2px 10px',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

const Order = () => {
  const [size, setSize] = useState('');
  const [quantity, setQunatity] = useState('');

  // for modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const placeOrder = () => {
    post('create-order', {quantity: quantity, size: size})
    .then(handleClose)
    .then(() => navigate('/'));
  }


  return (
    <div className="order-com">
      <div className="order-c">
        <h4 id="oh" style={{ textAlign: 'center'}}>Order Eggs</h4>
        <div className="order-form">
          <div className="egg-type">
            <p id="fld2">Egg Type:</p>
            <input id="dInl" className="rbtn"
              type="radio"
              name="size"
              onChange={(e) => setSize(e.target.value)}
              value="large"
            />
            <label id="dInl">Large</label>
            <input id="dInl" className="rbtn"
              type="radio"
              onChange={(e) => setSize(e.target.value)}
              name="size"
              value="medium"
            />
            <label id="dInl">Medium</label>
            <input id="dInl" className="rbtn"
              type="radio"
              name="size"
              onChange={(e) => setSize(e.target.value)}
              value="small"
            />
            <label id="dInl">Small</label>
          </div>

          
          <div className="egg-quantity">
            <p id="fld2">Egg Quantity:</p>
            <input
              id="inl"
              type="number"
              placeholder="5"
              onChange={(e) => setQunatity(e.target.value)}
              required
            />
            <p id="inl">boxes</p>
            <h6>note: 1 box contains 7 crates of eggs!</h6>
          </div>
          <p id="ob" onClick={() => {
            quantity && size
              ? handleOpen()
              : dispatch(handleMessage("Please enter egg type and quantity."))
            }} >PLACE ORDER</p>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Review Your Order
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {quantity} boxes, {size}.
              </Typography>

              <div style={{ marginTop: '10px' }}>
              <Button variant='outlined' onClick={handleClose}>Cancel</Button>
              <Button style={{ marginLeft: '10px' }} variant='contained' onClick={placeOrder}>Continue</Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Order;
