import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { backend_url } from './Navigation';
import { handleMessage, handleData } from '../store/loginSlice';
import '../styles/Login.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StoreIcon from '@mui/icons-material/Store';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ShortTextIcon from '@mui/icons-material/ShortText';

export const phoneNumberValidator = (phoneNumber) => {
	const phString = phoneNumber.toString();
	const firstDigit = phString[0];
	const secondDigit = phString[1];
	const err = {c: 0, d: "invalid phone number"};

	if (phString.length === 10){
		if (firstDigit === "9"){
			if (secondDigit === "7" || secondDigit === "8" || secondDigit === "6"){
				return {c: 1, d: phoneNumber};
			}
			return err;
		}
		return err;
	}
	return err;
}

const registerRequest = async (payload) => {
  let res = await axios.post(`${backend_url}/api/register`, payload);

  let data = res.data;
  return data.message;
};

const Register = () => {

  const [ username, setUsername ] = useState('');
  const [ fullName, setFullName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ storeAddress, setStoreAddress ] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const register = () => {
    if(username && fullName && password && storeAddress){
      dispatch(handleData(''))

      const r = phoneNumberValidator(username);
      if(r.c === 1){
        registerRequest({username : r.d, full_name: fullName, store_address: storeAddress, password: password})
        .then(m => {
          dispatch(handleData(m)); 
          dispatch(handleMessage(m)); 
          if(m === 'Welcome to Egg Nepal, registeration successfull.'){
            navigate('/login');
          }
        });
      } else {
        dispatch(handleData(r.d)); 
        dispatch(handleMessage(r.d)); 
      }
    } else {
      dispatch(handleMessage('Please fill all the fields.'))
    }
  }

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div id='cdiv'>
    <Box id='rdiv'>
      <FormControl variant="standard"
      sx={{ m: 1, width: '96%' }} 
      >
        <InputLabel htmlFor="input-with-icon-adornment">
          Username
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          placeholder='phone number'
          type="number"
          onChange={e => setUsername(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl variant="standard"
      sx={{ m: 1, width: '96%' }} 
      >
        <InputLabel htmlFor="input-with-icon-adornment">
          Full Name
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          placeholder='Ram Kumar'
          onChange={e => setFullName(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <ShortTextIcon />
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl variant="standard"
      sx={{ m: 1, width: '96%' }} 
      >
        <InputLabel htmlFor="input-with-icon-adornment">
          Store Address
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          placeholder='Akashedhara, Kapan'
          onChange={e => setStoreAddress(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <StoreIcon />
            </InputAdornment>
          }
        />
      </FormControl>    

      <FormControl
        sx={{ m: 1, width: '96%' }}
        variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              placeholder='********'
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              onChange={e => setPassword(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LockPersonIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
      </FormControl>

      <Button 
        variant="contained"
        onClick={register}
        style={{
            marginTop: '10px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }}
      >Register</Button>
  </Box>
  </div>
  );
};

export default Register;
