import bgOne from '../images/home_bg.jpg';
import bgTwo from '../images/home_bg - h.jpg';
import '../styles/Login.css';

const Home = () => {

  return (
    <div id="main">
      <img id="home_bg1" src={bgOne} alt="background" />
      <img id="home_bg2" src={bgTwo} alt="background" />

      <p id="suptxt">Order nice and fresh eggs</p>

      <div id="suptxt2" >
        <div id='desc' style={{ textAlign: 'justify' }} >We deliver clean and fresh eggs to stores. If you are a store owner and wish to order eggs, please register to create an account and login into the system.</div>
      </div>
    </div>
  );
};

export default Home;
