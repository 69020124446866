import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: '0',
  user: {},
  token: '',
  message: '',
  data: 'sample data for loading',
};

export const loginSlice = createSlice({
  name: 'login', // name of the slice
  initialState,
  reducers: {
    handleLogin: (state, isLoggedIn) => {
      state.isLoggedIn = isLoggedIn.payload;
    },
    handleMessage: (state, message) => {
      state.message = message.payload;
    },
    handleUser: (state, user) => {
      state.user = user.payload;
    },
    handleToken: (state, token) => {
      state.token = token.payload;
    },
    handleData: (state, data) => {
      state.data = data.payload;
    }
  },
});

export const {
  handleMessage,
  handleUser,
  handleLogin,
  handleToken,
  handleData,
} = loginSlice.actions;

export default loginSlice.reducer;
