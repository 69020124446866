import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { UserDiv } from './UserProfile';
import { get, post } from './Navigation';
import { useState, useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '10px 10px 2px 10px',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

const ResetPassword = ({id, getAllUsers}) => {

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const resetPassword = () => {
    post('reset-password', {id: id})
    .then(getAllUsers)
    .then(handleClose2);
  }

  return(
    <div style={{ display: 'inline' }}>
      <div id='btnn'>
        <Button variant="contained"  onClick={handleOpen2}>Reset Password</Button>
      </div>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reset Password Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to reset the password?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose2}>Cancel</Button>
          <Button variant="contained" onClick={resetPassword}>Reset</Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

const AdminProfile = () => {

  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')

  const getAllUsers = () => {
    get('users').then(data => {setUsers(data)})
  }

  useEffect( getAllUsers, []);

  return (
    <div className="orders">
      <UserDiv />

      <h4 id="oh" style={{ textAlign: 'center', marginBottom: '10px'}}>Users / Customers</h4>

      <Paper
      id='search-b'
      component="form"
      sx={{ p: '2px 4px' }}
      >
      <InputBase
        onChange={e => setSearch(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Users By Full Name"
        inputProps={{ 'aria-label': 'search users by full name' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>

      <div id="i-orders">
        {users
        .filter(user => {
          return search.toLowerCase() === ''
            ? user
            : user.full_name.toLowerCase().includes(search);
        })
        .map(user => (
          <div className="order" style={{ background: 'linear-gradient(153deg, rgba(2,0,36,1) 0%, rgba(13,13,18,1) 66%, rgba(167,179,181,1) 100%)' }} key={user.id}>
            <h3 id="name">{user.full_name}</h3>
            <p id="address">{user.store_address}</p>
            <h4>{user.username}</h4>

            {user.needs_reset && (
              <ResetPassword id={user.id} getAllUsers={getAllUsers} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminProfile;
