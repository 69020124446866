import axios from 'axios';
import '../styles/Login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {useState} from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { handleLogin, handleMessage, handleToken, handleUser, handleData } from '../store/loginSlice';
import { backend_url } from './Navigation';
import { phoneNumberValidator } from './Register';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '10px 10px 2px 10px',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

const loginRequest = async (payload) => {
  let res = await axios.post(`${backend_url}/api/login`, payload);

  let data = res.data; //has data and message
  return data;
};

const requestPasswordResetRequest = async (payload) => {
  let res = await axios.post(`${backend_url}/api/request-password-reset`, payload);

  let data = res.data; //has data and message
  return data.message;
};

const RequestPasswordReset = () => {

  const dispatch = useDispatch();

  const [username, setUsername] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return(
    <div>
      <div style={{ padding: '10px 5px 5px 0px', fontSize: '12px' }} > 
        <p onClick={handleOpen} style={{ margin: 0, padding: 0, width: 'fit-content', marginLeft: 'auto'}}>Forgot Password?</p>
      </div>
      <Modal
              id='popup'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Password Reset
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: 'justify' }}>
                  A request will be sent to the admin to reset your password. You will be contacted by the admin after the password reset.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please enter your username and submit.
                </Typography>

                <FormControl variant="standard"
                sx={{ m: 1, width: '96%' }} 
                >
                  <InputLabel htmlFor="input-with-icon-adornment">
                    Username
                  </InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    placeholder='phone number'
                    type="number"
                    onChange={e => setUsername(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={() => {
                  dispatch(handleData(''));
                  if(username){
                    const r = phoneNumberValidator(username);
                    if(r.c === 1){
                      requestPasswordResetRequest({username: username})
                      .then(m => {dispatch(handleMessage(m)); dispatch(handleData(m));})
                      .then(handleClose)
                    } else {
                      dispatch(handleData(r.d)); 
                      dispatch(handleMessage("Invalid Username"));
                    }
                  } else{
                    dispatch(handleMessage('Please Enter your Username'));
                  }
                }}>Submit</Button>
                </div>
              </Box>
      </Modal>
    </div>
  );
}

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // login state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = async () => {
    if (username && password) {
      // write code for loading until getting response
      dispatch(handleData('')); // to display loading

      const data = await loginRequest({username: username, password: password});

      dispatch(handleData(data.message)); // to remove loading
      
      if(data.message === 'Login successful.'){

        dispatch(handleMessage('Welcome to Egg Nepal.'));

        dispatch(handleLogin('1'));
        dispatch(handleToken(data.token));
        dispatch(handleUser(data.data));

        const type = data.data.user_type;
        if( type === 2 ){
          navigate('/order');
        }else if( type === 1 ){
          navigate('/orders');
        }
        
        // save this to local storage too: for saving login
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.data));
      } else {
        dispatch(handleMessage(data.message));
      }
    } else {
      dispatch(handleMessage('Username and password required!'));
    }
  };

  return (
    <div id='cdiv'>
    <Box id='ldiv'>
      {/* <CardMedia
        style={{ position: 'inherit', width: '200px', marginTop: '0px', marginLeft: 'auto', marginRight: 'auto' }}
        component="img"
        id="logo"
        image={logo}
        alt="EGG NEPAL"
      /> */}

      <FormControl variant="standard"
      sx={{ m: 1, width: '96%' }} 
      >
        <InputLabel htmlFor="input-with-icon-adornment">
          Username
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          placeholder='phone number'
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
          onChange={e => setUsername(e.target.value)}
        />
      </FormControl>

      <FormControl
        sx={{ m: 1, width: '96%' }} 
        variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            placeholder='********'
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            startAdornment={
              <InputAdornment position="start">
                <LockPersonIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={e => setPassword(e.target.value)}
          />
      </FormControl>

      <RequestPasswordReset />

      <Button 
          variant="contained"
          style={{
            marginTop: '10px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={login}
      >Login</Button>
  </Box>
  </div>
  );
};

export default Login;
