import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AlertMessage } from './components/Navigation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleLogin, handleToken, handleUser, handleMessage } from './store/loginSlice';
import './styles/App.css';
import ViewOrders from './components/ViewOrders';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Order from './components/Order';
import AdminProfile from './components/AdminProfile';
import UserProfile from './components/UserProfile';
import Loading from './components/Loading';
import Navigation from './components/Navigation';
import About from './components/About';
import LoginProtected, { LogoutProtected, AdminProtected, UserProtected, NoMatch } from './components/Proctected';

const App = () => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    dispatch(handleMessage('Welcome to Egg Nepal'));

    if( isLoggedIn && token && user ){
      dispatch(handleLogin(isLoggedIn));
      dispatch(handleToken(token));
      dispatch(handleUser(JSON.parse(user)));
    }
  }, [dispatch]);

  // }, [isLoggedIn, token, user, dispatch])
  

  // const sIsLoggedIn = useSelector((state) => state.login.isLoggedIn);
  // const sUser = useSelector((state) => state.login.user);
  // console.log(sIsLoggedIn);
  // console.log(sUser);

  return (
    <BrowserRouter>
      <Navigation />
      <Loading />
      <AlertMessage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/login" element={
          <LogoutProtected>
            <Login />
          </LogoutProtected> } />

        <Route path="/register" element={
          <LogoutProtected>
            <Register />
          </LogoutProtected> } />
        
        <Route path="/orders" element={
          <LoginProtected >
            <AdminProtected>
              <ViewOrders />
            </AdminProtected>
          </LoginProtected>} />

        <Route path="/admin-profile" element={
          <LoginProtected >
            <AdminProtected>
              <AdminProfile />
            </AdminProtected>
          </LoginProtected>} />

        <Route path="/order"  element={
          <LoginProtected >
            <UserProtected>
              <Order />
            </UserProtected>
          </LoginProtected>} />
          
        <Route path="/user-profile"  element={
          <LoginProtected >
            <UserProtected>
              <UserProfile />
            </UserProtected>
          </LoginProtected>} />
        
        <Route path="*" element={<NoMatch />} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
