import { useSelector } from "react-redux";

export const NoMatch = () => {

    const style = {
        fontSize: '30px',
        fontWeight: 'bold',
        letterSpacing: '2px',
        opacity: '60%',
        textAlign: 'center',
        marginTop: '15%'
    };

    return(
        <div style={style}>
            404 Page Not Found!
        </div>
    );
}

export const AdminProtected = ({ children }) => {
    const user = useSelector((state) => state.login.user);
  
    if (user.user_type === 1) {
        return children;
    } else {
        return <NoMatch />;
    }
};

export const UserProtected = ({ children }) => {
    const user = useSelector((state) => state.login.user);
  
    if (user.user_type === 2) {
        return children;
    } else {
        return <NoMatch />;
    }
};

export const LogoutProtected = ({ children }) => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  
    if (isLoggedIn === '1') {
        return <NoMatch />;
    } else {
        return children;
    }
};

const LoginProtected = ({ children }) => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  
    if (isLoggedIn !== '1') {
        return <NoMatch />;
    } else {
        return children;
    }
};

export default LoginProtected;