import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/App.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Alert } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import logo from '../images/enlogo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { handleMessage, handleData, handleLogin, handleToken, handleUser } from '../store/loginSlice';

// this component is used to display all the messages to the user.
export const AlertMessage = () => {

  const message = useSelector((state) => state.login.message);

  const dispatch = useDispatch();

  setTimeout( // a information message is displayed for two seconds
    () => dispatch(handleMessage('')),
    3000
  )

  if(message){
    return <Alert style={{ position: 'absolute', zIndex: '5000', top: '0', right: '0', margin: '80px 10px' }}  severity="info">{message}</Alert>;
  }else{
    return <></>;
  }
};

// axios requests will be sent using these methods.
export let headers = {};
export let post; 
export let get;
export const backend_url = 'https://eggnepal.cyclic.app';
// export const backend_url = 'http://localhost:3595';

const Navigation = () => {

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const token =  useSelector((state) => state.login.token);
  const user =  useSelector((state) => state.login.user);

  const dispatch = useDispatch();

  // -----------get and post methods initialized----------
  headers = {
    authorization: 'Bearer'.concat(' ', token),
  };
  
  post = async (api_key, payload) => {
  try{
    dispatch(handleData(''));

    let ress = await axios.post(
      backend_url.concat('/api/', api_key),
      payload,
      { headers }
    );
    const m = ress.data.message;
    dispatch(handleData(m));
    dispatch(handleMessage(m));

    if(m === 'Token has expired, please login!'){
        dispatch(handleLogin('0'));
        navigate('/login');
    }
  } catch (e) {
      console.log('something went wrong!');
      dispatch(handleData('something went wrong!'));
      dispatch(handleMessage(e.response.data.message));
  }
  }

  get = async (api_key) => {
    try{
      dispatch(handleData(''));
      let ress = await axios.get(
        backend_url.concat('/api/', api_key),
        { headers }
      );
      const m = ress.data.message;
      dispatch(handleData(m));
      if(m === 'Token has expired, please login!'){
        dispatch(handleLogin('0'));
        navigate('/login');
      } else {
        return ress.data.data;
      }
    } catch (e) {
      console.log('somethig went wrong');
    }
  }
  // ---------------------

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  const logout = () => {
    dispatch(handleMessage('Logout Successful.'));
    dispatch(handleLogin('0'));
    dispatch(handleToken(''));
    dispatch(handleUser({}));
    handleRedirect('/');
    localStorage.clear();
  }

  return (
      <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => handleRedirect('/')}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <CardMedia
              style={{ position: 'inherit', marginTop: '0px' }}
              component="img"
              id="logo"
              image={logo}
              alt="EGG NEPAL"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {isLoggedIn !== '1' && 
              (<Link to="/login" id="link">
                <MenuItem key="login" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">login</Typography>
                </MenuItem>
              </Link>)}

              { isLoggedIn !== '1' && 
              (<Link to="/register" id="link">
                <MenuItem key="register" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">register</Typography>
                </MenuItem>
              </Link>)}
              
              <Link to="/about" id="link">
                <MenuItem key="about" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">about us</Typography>
                </MenuItem>
              </Link>

              { isLoggedIn === '1' && user.user_type === 2 && (
              <Link to="/order" id="link">
                <MenuItem key="order" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">order</Typography>
                </MenuItem>
              </Link>)}

              { isLoggedIn === '1' && user.user_type === 1 && (
              <Link to="/orders" id="link">
                <MenuItem key="orders" onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">orders</Typography>
                </MenuItem>
              </Link>)}

            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => handleRedirect('/')}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <CardMedia
              style={{ position: 'inherit', marginTop: '0px' }}
              component="img"
              id="logo"
              image={logo}
              alt="EGG NEPAL"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
            { isLoggedIn !== '1' && 
            (<Link to="/login" id="link">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >login</Button>
            </Link>)}

            { isLoggedIn !== '1' && 
            (<Link to="/register" id="link">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >register</Button>
            </Link>
            )}
            
            <Link to="/about" id="link">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >about us</Button>
            </Link>

            { isLoggedIn === '1' && user.user_type === 2 &&
            (<Link to="/order" id="link">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >order</Button>
            </Link>)}

            { isLoggedIn === '1' && user.user_type === 1 &&
            (<Link to="/orders" id="link">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >orders</Button>
            </Link>)}
          </Box>

          { isLoggedIn === '1' && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircle 
                  sx={{ color: 'white',
                  width: '30px', height: '30px'
                  }}
                />
              </IconButton>
            </Tooltip>
            
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}

              { user.user_type === 1 && (
              <MenuItem key='/admin-profile' onClick={() => {handleRedirect('/admin-profile'); handleCloseUserMenu()}}>
                  <Typography textAlign="center">profile</Typography>
              </MenuItem>)}

              { user.user_type === 2 && (
              <MenuItem key='/user-profile' onClick={() => {handleRedirect('/user-profile'); handleCloseUserMenu()}}>
                  <Typography textAlign="center">profile</Typography>
              </MenuItem>)}

              
              <MenuItem key='logout' onClick={() => { logout(); handleCloseUserMenu();}}>
                    <Typography textAlign="center">logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
          )}
        </Toolbar>
      </Container>
      </AppBar>
  );
};

export default Navigation ;
