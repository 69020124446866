import { useState, useEffect } from 'react';
import { get, post } from './Navigation';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '10px 10px 2px 10px',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

const Deliver = ({id, getAllOrders}) => {

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const deliverOrder = () => {
    post('deliver-order', {id: id})
    .then(getAllOrders)
    .then(handleClose2);;
  };

  return (
    <div style={{ display: 'inline' }}>
      <div id='btnn'>
        <Button variant="contained" onClick={handleOpen2}>Delivered</Button>
      </div>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Order Delivery Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure the order is delivered?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleClose2}>No</Button>
          <Button variant="contained" onClick={deliverOrder}>Yes</Button>
          </div>
        </Box>
        </Modal>
    </div>
  );
}

const ViewOrders = () => {

  const [orders, setOrders] = useState([]);

  const getAllOrders = () => {
    get('all-orders').then(data => {
      setOrders(data);
    });
  };

  useEffect(getAllOrders, []);

  return (
    <div className="orders">
      <h4 id="oh" style={{ textAlign: 'center'}}>Orders To Deliver</h4>
      <div id="i-orders">

      {orders.map(order => (
        <div className="order" key={order.id}>
          <h3 id="name">{order.full_name}</h3>
          <p id="address">{order.store_address}</p>
          <h4>{order.quantity} Boxes, {order.size}</h4>

          {!order.is_delivered && (
            <Deliver id={order.id} getAllOrders={getAllOrders} />
          )}
        </div>
      ))}
      </div>
    </div>
  );
};

export default ViewOrders;
